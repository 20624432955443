import Preloader from './ts/preloader';
import TriggerNav from './ts/trigger-nav';

// styles
import './styles/base.css';
import './styles/main.css';
import './fonts/stylesheet.css';
import './styles/components/popup.css';
import './styles/components/social-icons.css';
import './styles/components/preloader.css';
import './styles/components/leaderboard.css';
import './styles/components/service-btns.css';
import './css/style.css';

(function () {
  Preloader(); // starts preloader
  TriggerNav(); // activates nav
})();
