import $ from 'jquery';

export default function Preloader() {
  let loaded = false;
  $('.preloader').fadeIn(); // preloader enters

  document.onreadystatechange = completeLoading;
  //Remove loading effect when loading state is complete
  function completeLoading() {
    if (document.readyState == 'complete') {
      console.log('completed');

      setTimeout(() => {
        $('.preloader').fadeOut();
      }, 4000);
    }
  }
}
