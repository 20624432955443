export default function TriggerNav() {
  //NAV//
  const navTrigger = document.querySelector('.nav-trigger');
  const menuPanel = document.querySelector('.menu-panel');
  navTrigger!.addEventListener('click', () => {
    navTrigger!.classList.toggle('active');
    menuPanel!.classList.toggle('active');
    document.querySelector('body')!.classList.toggle('no-scroll');
  });
}
